/**
 * Sitemap
 * 	Referência: https://github.com/cheap-glitch/vue-cli-plugin-sitemap#readme
 * 	Adicionar o seguinte na rota em que precise alterar manualmente os valores do sitemap, com os respectivos valores:
		meta: {
			sitemap: {
				lastmod: new Date(),
				priority: 0.8,
				changefreq: 'daily',
			}
		}
 */

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '@views/Home/Home'),
	},
]

export default routes
