<template lang="pug">
	footer#footer
		h1
			|C.alpha Home
			img(:src="require('@images/logo.png')", alt="C.alpha Home")
		ul
			li
				img(:src="require('@images/icons/pin.png')")
				p Avenida do Batel, 1750. CEP 80420-090 | Curitiba - Paraná
			li
				img(:src="require('@images/icons/phone.png')")
				a(href="tel:5541999676223") (41) 99967 6223
</template>

<script>
export default {
  name: 'component-footer'
}
</script>

<style lang="stylus" scoped src="./Footer.styl"></style>
