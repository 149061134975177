<template lang="pug">
	header#header(:class="{'ativo': ativo}")
		h1
			|C.alpha Home
			img(:src="require('@images/logo.png')", alt="C.alpha Home")
		button(@click="toggleMenu()").menu
			i.first
			i.second
			i.third
		ul
			li
				button(@click="scrollTo('novoProjeto')") Quem somos
			li
				button(@click="scrollTo('contato')") Contato
</template>

<script>
export default {
	name: 'component-header',
	data(){
		return{
			ativo: false
		}
	},
	methods: {
		scrollTo(target){
			this.closeMenu()
			setTimeout(() => {
				const element = document.getElementById(target)
				if (element) {
					window.scrollTo({
						top: element.offsetTop - 105,
						left: 0,
						behavior: 'smooth'
					})
				}
			}, 100)
		},
		toggleMenu() {
			this.ativo = !this.ativo
		},
		closeMenu() {
			this.ativo = false
		}
	}
}
</script>

<style lang="stylus" scoped src="./Header.styl"></style>
